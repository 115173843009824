<template>
  <div class="mr-4">
    <h4 class="text-danger">Klucz</h4>

    <div v-if="baseTranslations && Object.keys(baseTranslations).length > 0">
      <div v-for="(value, key) in baseTranslations" :key="`base-language-key-${key}`" class="row mb-2">
        <div class="col-12">
          <input :value="key" class="form-control custom-input" readonly type="text"/>
        </div>
      </div>
    </div>
    <div v-else>
      <h4>Brak tłumaczeń.</h4>
    </div>
  </div>
</template>

<script>
export default {
  props: ["baseTranslations"]
}
</script>

<style scoped>
.custom-input {
  width: 350px !important;
}
</style>